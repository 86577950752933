export const adminConfig = {
  authClientId: 'lk7vn6qApkLLMW1VrgeLlHbeMQViGlrO',
  sentryDsn: 'https://0d91e632b0c54437b20488a3d79a0ee7@o463927.ingest.sentry.io/5493818',
  auth: {
    domain: 'cognito.benefitsapi.com',
    client_id: '6l7jeu4r44kgndgeab4aot355m',
    redirect_uri: process.env.REACT_APP_AUTH_REDIRECT || 'https://admin.getbenepass.com/?v=2',
    client: process.env.REACT_APP_AUTH_CLIENT || 'adminDash',
    signOnUrl: process.env.REACT_APP_SIGN_ON_URL || 'https://signon.benefitsapi.com/',
  },
};

export const adminFeatures = {
  filtering: true,
  home: true,
  benefits: true,
  account: true,
  reports: true,
  company: true,
  roster: true,
  integrations: true,
  funding: true,
  exchangeRates: true,
  uploads: true,
};
